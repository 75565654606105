export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  ONBOARDING = '/onboarding',
  ONBOARDING_STEP_2 = '/onboarding/step-2',
  ONBOARDING_STEP_3 = '/onboarding/step-3',
  REGISTRATION = '/registration',
  REGISTRATION_STEP_2 = '/registration/step-2',
  MEMBERS = '/members',
  MEMBER_DETAILS = '/members/:id',
  MEDICATIONS = '/medications',
  MEDICATION_DETAILS = '/medications/:id',
  ADD_DEPENDENT = '/add-dependent',
  ADD_DEPENDENT_STEP_2 = '/add-dependent/step-2',
  ADD_DEPENDENT_STEP_3 = '/add-dependent/step-3',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
}

/** Get a route with param interpolated with correct ID */
export const routeWithParam = (route: Routes, id: string): string => {
  return route.replace(':id', id);
};
