import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: string;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: string;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
};

/** An ARAccount from Keycentrix */
export type ArAccount = {
  __typename?: 'ARAccount';
  accountName?: Maybe<Scalars['String']>;
  customerGroups: Array<ArCustomerGroup>;
  id?: Maybe<Scalars['ID']>;
  responsibleParty?: Maybe<Scalars['String']>;
};

/** An ARCustomerGroup from Keycentrix */
export type ArCustomerGroup = {
  __typename?: 'ARCustomerGroup';
  arAccountId?: Maybe<Scalars['String']>;
  customer: Customer;
  customerId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

/** Payload returned if login or signup is successful */
export type AuthPayload = {
  __typename?: 'AuthPayload';
  /** The current JWT token. Use in Authentication header */
  token?: Maybe<Scalars['String']>;
  /** The logged in user */
  user?: Maybe<User>;
};

/** Input to create a customer address */
export type CreateCustomerAddressInput = {
  addressOne: Scalars['String'];
  addressTwo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  customerId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

/** Input to create a customer */
export type CreateCustomerInput = {
  dateOfBirth: Scalars['Date'];
  email?: Maybe<Scalars['EmailAddress']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateRefill = {
  __typename?: 'CreateRefill';
  id?: Maybe<Scalars['ID']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  rxNumber?: Maybe<Scalars['Int']>;
};

/** A Customer */
export type Customer = {
  __typename?: 'Customer';
  arAccount: ArAccount;
  createdAt: Scalars['DateTime'];
  /** Addresses for customer */
  customerAddresses: Array<CustomerAddress>;
  /** Allergies for customer */
  customerAllergies: Array<CustomerAllergy>;
  /** Conditions for customer */
  customerConditions: Array<CustomerCondition>;
  dateOfBirth: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isDependent?: Maybe<Scalars['Boolean']>;
  isOverEighteen?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  migratedAccount?: Maybe<Scalars['Boolean']>;
  /** Primary phone number for customer */
  phone?: Maybe<CustomerPhoneNumber>;
  /** Profile for customer */
  profile?: Maybe<Profile>;
  /** Prescriptions for customer */
  rxes: Array<Rx>;
  suffix?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** A customer address */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  addressOne: Scalars['String'];
  addressTwo?: Maybe<Scalars['String']>;
  addressType: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
};

/** Customer Allergies */
export type CustomerAllergy = {
  __typename?: 'CustomerAllergy';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** Customer Conditions */
export type CustomerCondition = {
  __typename?: 'CustomerCondition';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** A phone number */
export type CustomerPhoneNumber = {
  __typename?: 'CustomerPhoneNumber';
  isMobile: Scalars['Boolean'];
  isSMS: Scalars['Boolean'];
  phoneNumber: Scalars['PhoneNumber'];
};



/** A Drug */
export type Drug = {
  __typename?: 'Drug';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fixedDiscardDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isGeneric: Scalars['Boolean'];
  isRx: Scalars['Boolean'];
  isStocked: Scalars['Boolean'];
  manufacturerName: Scalars['String'];
  packageDescriptionCode: Scalars['String'];
  packageQuantity: Scalars['Float'];
  packageSize: Scalars['Float'];
  packageSizeUnitCode: Scalars['String'];
  statusCode: Scalars['String'];
  strength: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  useFixedDiscardDate: Scalars['Boolean'];
};



/** Filter to lookup customers */
export type LookupCustomerWhereInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a dependent to account */
  addDependent?: Maybe<Customer>;
  /** Creates a customer address */
  createCustomerAddress?: Maybe<Array<CustomerAddress>>;
  createRefill: CreateRefill;
  /** Sends a reset password link to a user if the email is valid */
  forgotPassword?: Maybe<Scalars['Boolean']>;
  /** Login to an existing account */
  login?: Maybe<AuthPayload>;
  /** Register an account */
  register?: Maybe<Scalars['Boolean']>;
  /** Resets a password using a valid reset token */
  resetPassword?: Maybe<AuthPayload>;
  /** Updates a customer */
  updateCustomer?: Maybe<Customer>;
  /** Updates a customer address */
  updateCustomerAddress?: Maybe<CustomerAddress>;
  updateProfile?: Maybe<Profile>;
  /** Sets the password using a valid claim account token */
  verifyRegistration?: Maybe<AuthPayload>;
};


export type MutationAddDependentArgs = {
  data: CreateCustomerInput;
};


export type MutationCreateCustomerAddressArgs = {
  data: CreateCustomerAddressInput;
};


export type MutationCreateRefillArgs = {
  rxNumber: Scalars['Int'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  resetToken: Scalars['String'];
  tokenId: Scalars['String'];
};


export type MutationUpdateCustomerArgs = {
  data: UpdateCustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  data: UpdateCustomerAddressInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
  where: ProfileWhereUniqueInput;
};


export type MutationVerifyRegistrationArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};


/** Prescriber information */
export type Prescriber = {
  __typename?: 'Prescriber';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  middleInitial?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** A profile to keep track of customer customizations */
export type Profile = {
  __typename?: 'Profile';
  cardColor?: Maybe<ProfileColor>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export enum ProfileColor {
  BLUE = 'BLUE',
  CYAN = 'CYAN',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  RED = 'RED',
  TEAL = 'TEAL',
  YELLOW = 'YELLOW'
}

export type ProfileWhereUniqueInput = {
  customerId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Gets customer by id */
  customer: Customer;
  /** Returns customers by lookup */
  lookupCustomer?: Maybe<Customer>;
  /** Returns the currently logged in user */
  me?: Maybe<User>;
  /** Get prescriber information by Prescriber ID */
  prescriber: Prescriber;
  /** Get a prescription by ID */
  rx: Rx;
  user: User;
};


export type QueryCustomerArgs = {
  id: Scalars['String'];
};


export type QueryLookupCustomerArgs = {
  where: LookupCustomerWhereInput;
};


export type QueryPrescriberArgs = {
  id: Scalars['ID'];
};


export type QueryRxArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

/** A Refill */
export type Refill = {
  __typename?: 'Refill';
  createdAt: Scalars['DateTime'];
  customerArAccountId: Scalars['String'];
  dateFilled: Scalars['String'];
  daysSupply: Scalars['Float'];
  destinationDate: Scalars['String'];
  destinationNotes: Scalars['String'];
  discardAfterDate: Scalars['String'];
  dispensedQuantity: Scalars['Float'];
  dosesPerDay: Scalars['Float'];
  /** Drug for refill */
  drug: Drug;
  drugId: Scalars['String'];
  effectiveDate: Scalars['String'];
  equivalentDrugDescription: Scalars['String'];
  id: Scalars['ID'];
  isDispensed: Scalars['Boolean'];
  isOrdered: Scalars['Boolean'];
  isPrefill: Scalars['Boolean'];
  maxDaysSupply: Scalars['Float'];
  minDaysSupply: Scalars['Float'];
  numberOfLabels: Scalars['Int'];
  numberOfPieces: Scalars['Float'];
  refillNumber: Scalars['Int'];
  /** Patient copay, in dollars */
  responsePatientPayAmount: Scalars['Float'];
  rxId: Scalars['String'];
  scanoutDate?: Maybe<Scalars['String']>;
  /** Drug description/label */
  sig: Scalars['String'];
  /** Drug description/label expanded */
  sigExpanded: Scalars['String'];
  status: Scalars['Int'];
  /** Returns tracking info by parsing refill comments */
  trackingInfo?: Maybe<TrackingInfo>;
  unitsPerDose: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  verifiedSIGExpanded: Scalars['String'];
  workflowStatus: Scalars['Int'];
};

/** Input required for a user to register */
export type RegisterInput = {
  customerId: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

/** A prescription */
export type Rx = {
  __typename?: 'Rx';
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  dateExpires?: Maybe<Scalars['String']>;
  dateWritten: Scalars['String'];
  id: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  originalDaysSupply: Scalars['Float'];
  originalQuantity: Scalars['Float'];
  /** Prescribed drug description/label */
  originalSig: Scalars['String'];
  /** Prescribed drug description/label expanded */
  originalSigExpanded: Scalars['String'];
  /** Prescribed drug for prescription */
  prescribedDrug: Drug;
  prescribedDrugId: Scalars['String'];
  /** Prescriber Information */
  prescriber: Prescriber;
  prescriberId: Scalars['String'];
  /** Refills for prescription */
  refills: Array<Refill>;
  refillsAuthorized: Scalars['Float'];
  refillsRemaining: Scalars['Float'];
  rxNumber: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Sort direction for filtering queries (ascending or descending) */
export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

/** A way to filter string fields. Meant to pass to prisma where clause */
export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type TrackingInfo = {
  __typename?: 'TrackingInfo';
  service: Scalars['String'];
  trackingNumber: Scalars['String'];
};


/** Input to update a customer address */
export type UpdateCustomerAddressInput = {
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  id: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Input to update a customer */
export type UpdateCustomerInput = {
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<UpdateCustomerPhoneInput>;
};

/** Input to update a customer phone */
export type UpdateCustomerPhoneInput = {
  isMobile?: Maybe<Scalars['Boolean']>;
  isSMS?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']>;
};

export type UpdateProfileInput = {
  cardColor: ProfileColor;
};

/** A User */
export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roles: Array<Role>;
  updatedAt: Scalars['DateTime'];
};

/** Order users by a specific field */
export type UserOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Input to find users based other fields */
export type UserWhereInput = {
  email?: Maybe<StringFilter>;
  id?: Maybe<Scalars['Int']>;
};

/** Input to find users based on unique fields */
export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type LookupCustomerFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'firstName' | 'email'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'customerId' | 'createdAt' | 'email' | 'updatedAt'>
);

export type CustomerFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'dateOfBirth' | 'isOverEighteen' | 'suffix' | 'createdAt' | 'updatedAt' | 'migratedAccount'>
);

export type ProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'customerId' | 'cardColor'>
);

export type CustomerWithProfileFragment = (
  { __typename?: 'Customer' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & ProfileFragment
  )> }
  & CustomerFragment
);

export type CustomerPhoneNumberFragment = (
  { __typename?: 'CustomerPhoneNumber' }
  & Pick<CustomerPhoneNumber, 'phoneNumber' | 'isMobile' | 'isSMS'>
);

export type CustomerWithFullInfoFragment = (
  { __typename?: 'Customer' }
  & { phone?: Maybe<(
    { __typename?: 'CustomerPhoneNumber' }
    & CustomerPhoneNumberFragment
  )>, customerAddresses: Array<(
    { __typename?: 'CustomerAddress' }
    & CustomerAddressFragment
  )>, customerAllergies: Array<(
    { __typename?: 'CustomerAllergy' }
    & CustomerAllergyFragment
  )>, customerConditions: Array<(
    { __typename?: 'CustomerCondition' }
    & CustomerConditionFragment
  )> }
  & CustomerWithProfileFragment
);

export type CustomerConditionFragment = (
  { __typename?: 'CustomerCondition' }
  & Pick<CustomerCondition, 'id' | 'description'>
);

export type CustomerAllergyFragment = (
  { __typename?: 'CustomerAllergy' }
  & Pick<CustomerAllergy, 'id' | 'description'>
);

export type CustomerAddressFragment = (
  { __typename?: 'CustomerAddress' }
  & Pick<CustomerAddress, 'id' | 'customerId' | 'addressOne' | 'addressTwo' | 'city' | 'state' | 'zip' | 'country' | 'isPrimary' | 'createdAt' | 'updatedAt'>
);

export type ArAccountFragment = (
  { __typename?: 'ARAccount' }
  & Pick<ArAccount, 'id' | 'accountName' | 'responsibleParty'>
);

export type ArCustomerGroupFragment = (
  { __typename?: 'ARCustomerGroup' }
  & Pick<ArCustomerGroup, 'arAccountId' | 'customerId' | 'isActive' | 'isPrimary'>
);

export type DrugFragment = (
  { __typename?: 'Drug' }
  & Pick<Drug, 'description' | 'id' | 'manufacturerName' | 'statusCode' | 'strength'>
);

export type TrackingInfoFragment = (
  { __typename?: 'TrackingInfo' }
  & Pick<TrackingInfo, 'service' | 'trackingNumber'>
);

export type PrescriberFragment = (
  { __typename?: 'Prescriber' }
  & Pick<Prescriber, 'id' | 'firstName' | 'lastName' | 'middleInitial' | 'suffix'>
);

export type RefillFragment = (
  { __typename?: 'Refill' }
  & Pick<Refill, 'id' | 'refillNumber' | 'rxId' | 'drugId' | 'destinationDate' | 'status' | 'dateFilled' | 'sig' | 'sigExpanded' | 'dispensedQuantity' | 'daysSupply' | 'minDaysSupply' | 'maxDaysSupply' | 'numberOfPieces' | 'scanoutDate' | 'isOrdered' | 'isDispensed' | 'isPrefill' | 'discardAfterDate' | 'workflowStatus' | 'numberOfLabels' | 'dosesPerDay' | 'unitsPerDose' | 'effectiveDate' | 'verifiedSIGExpanded' | 'responsePatientPayAmount'>
);

export type RxFragment = (
  { __typename?: 'Rx' }
  & Pick<Rx, 'customerId' | 'dateExpires' | 'dateWritten' | 'id' | 'isCancelled' | 'originalDaysSupply' | 'originalQuantity' | 'originalSig' | 'originalSigExpanded' | 'prescribedDrugId' | 'prescriberId' | 'refillsAuthorized' | 'refillsRemaining' | 'rxNumber'>
);

export type AddDependentMutationVariables = Exact<{
  data: CreateCustomerInput;
}>;


export type AddDependentMutation = (
  { __typename?: 'Mutation' }
  & { addDependent?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerWithProfileFragment
  )> }
);

export type AddDependentStep2QueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AddDependentStep2Query = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & { phone?: Maybe<(
      { __typename?: 'CustomerPhoneNumber' }
      & CustomerPhoneNumberFragment
    )> }
    & CustomerWithProfileFragment
  ) }
);

export type CustomerWithAddressQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerWithAddressQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { customerAddresses: Array<(
      { __typename?: 'CustomerAddress' }
      & CustomerAddressFragment
    )> }
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  )> }
);

export type MedicationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MedicationQuery = (
  { __typename?: 'Query' }
  & { rx: (
    { __typename?: 'Rx' }
    & Pick<Rx, 'prescriberId'>
    & { prescribedDrug: (
      { __typename?: 'Drug' }
      & DrugFragment
    ), prescriber: (
      { __typename?: 'Prescriber' }
      & PrescriberFragment
    ), refills: Array<(
      { __typename?: 'Refill' }
      & { trackingInfo?: Maybe<(
        { __typename?: 'TrackingInfo' }
        & TrackingInfoFragment
      )>, drug: (
        { __typename?: 'Drug' }
        & DrugFragment
      ) }
      & RefillFragment
    )> }
    & RxFragment
  ) }
);

export type CreateRefillMutationVariables = Exact<{
  rxNumber: Scalars['Int'];
}>;


export type CreateRefillMutation = (
  { __typename?: 'Mutation' }
  & { createRefill: (
    { __typename?: 'CreateRefill' }
    & Pick<CreateRefill, 'id' | 'rxNumber' | 'reasons'>
  ) }
);

export type MedicationsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MedicationsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
      & { arAccount: (
        { __typename?: 'ARAccount' }
        & { customerGroups: Array<(
          { __typename?: 'ARCustomerGroup' }
          & { customer: (
            { __typename?: 'Customer' }
            & { rxes: Array<(
              { __typename?: 'Rx' }
              & { prescribedDrug: (
                { __typename?: 'Drug' }
                & DrugFragment
              ) }
              & RxFragment
            )> }
            & CustomerWithProfileFragment
          ) }
          & ArCustomerGroupFragment
        )> }
        & ArAccountFragment
      ) }
    ) }
    & UserFragment
  )> }
);

export type CustomerAndDependentsWithAddressesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerAndDependentsWithAddressesQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { arAccount: (
      { __typename?: 'ARAccount' }
      & Pick<ArAccount, 'id'>
      & { customerGroups: Array<(
        { __typename?: 'ARCustomerGroup' }
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'firstName' | 'lastName'>
          & { customerAddresses: Array<(
            { __typename?: 'CustomerAddress' }
            & CustomerAddressFragment
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type MemberDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MemberDetailsQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & CustomerWithFullInfoFragment
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  data: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerWithFullInfoFragment
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  where: ProfileWhereUniqueInput;
  data: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'Profile' }
    & ProfileFragment
  )> }
);

export type UpdateCustomerAddressMutationVariables = Exact<{
  data: UpdateCustomerAddressInput;
}>;


export type UpdateCustomerAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerAddress?: Maybe<(
    { __typename?: 'CustomerAddress' }
    & CustomerAddressFragment
  )> }
);

export type CreateCustomerAddressMutationVariables = Exact<{
  data: CreateCustomerAddressInput;
}>;


export type CreateCustomerAddressMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerAddress?: Maybe<Array<(
    { __typename?: 'CustomerAddress' }
    & CustomerAddressFragment
  )>> }
);

export type MemberPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MemberPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { customer: (
      { __typename?: 'Customer' }
      & { arAccount: (
        { __typename?: 'ARAccount' }
        & { customerGroups: Array<(
          { __typename?: 'ARCustomerGroup' }
          & { customer: (
            { __typename?: 'Customer' }
            & CustomerWithProfileFragment
          ) }
          & ArCustomerGroupFragment
        )> }
        & ArAccountFragment
      ) }
      & CustomerFragment
    ) }
    & UserFragment
  )> }
);

export type OnboardingStep2QueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingStep2Query = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & { phone?: Maybe<(
        { __typename?: 'CustomerPhoneNumber' }
        & CustomerPhoneNumberFragment
      )> }
      & CustomerWithProfileFragment
    ) }
  )> }
);

export type RegisterMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type VerifyRegistrationMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
}>;


export type VerifyRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { verifyRegistration?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  tokenId: Scalars['String'];
  resetToken: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  )> }
);

export const LookupCustomerFragmentDoc = gql`
    fragment LookupCustomer on Customer {
  id
  firstName
  email
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  customerId
  createdAt
  email
  updatedAt
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  firstName
  middleName
  lastName
  email
  dateOfBirth
  isOverEighteen
  suffix
  createdAt
  updatedAt
  migratedAccount
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  id
  customerId
  cardColor
}
    `;
export const CustomerWithProfileFragmentDoc = gql`
    fragment CustomerWithProfile on Customer {
  ...Customer
  profile {
    ...Profile
  }
}
    ${CustomerFragmentDoc}
${ProfileFragmentDoc}`;
export const CustomerPhoneNumberFragmentDoc = gql`
    fragment CustomerPhoneNumber on CustomerPhoneNumber {
  phoneNumber
  isMobile
  isSMS
}
    `;
export const CustomerAddressFragmentDoc = gql`
    fragment CustomerAddress on CustomerAddress {
  id
  customerId
  addressOne
  addressTwo
  city
  state
  zip
  country
  isPrimary
  createdAt
  updatedAt
}
    `;
export const CustomerAllergyFragmentDoc = gql`
    fragment CustomerAllergy on CustomerAllergy {
  id
  description
}
    `;
export const CustomerConditionFragmentDoc = gql`
    fragment CustomerCondition on CustomerCondition {
  id
  description
}
    `;
export const CustomerWithFullInfoFragmentDoc = gql`
    fragment CustomerWithFullInfo on Customer {
  ...CustomerWithProfile
  phone {
    ...CustomerPhoneNumber
  }
  customerAddresses {
    ...CustomerAddress
  }
  customerAllergies {
    ...CustomerAllergy
  }
  customerConditions {
    ...CustomerCondition
  }
}
    ${CustomerWithProfileFragmentDoc}
${CustomerPhoneNumberFragmentDoc}
${CustomerAddressFragmentDoc}
${CustomerAllergyFragmentDoc}
${CustomerConditionFragmentDoc}`;
export const ArAccountFragmentDoc = gql`
    fragment ARAccount on ARAccount {
  id
  accountName
  responsibleParty
}
    `;
export const ArCustomerGroupFragmentDoc = gql`
    fragment ARCustomerGroup on ARCustomerGroup {
  arAccountId
  customerId
  isActive
  isPrimary
}
    `;
export const DrugFragmentDoc = gql`
    fragment Drug on Drug {
  description
  id
  manufacturerName
  statusCode
  strength
}
    `;
export const TrackingInfoFragmentDoc = gql`
    fragment TrackingInfo on TrackingInfo {
  service
  trackingNumber
}
    `;
export const PrescriberFragmentDoc = gql`
    fragment Prescriber on Prescriber {
  id
  firstName
  lastName
  middleInitial
  suffix
}
    `;
export const RefillFragmentDoc = gql`
    fragment Refill on Refill {
  id
  refillNumber
  rxId
  drugId
  destinationDate
  status
  dateFilled
  sig
  sigExpanded
  dispensedQuantity
  daysSupply
  minDaysSupply
  maxDaysSupply
  numberOfPieces
  scanoutDate
  isOrdered
  isDispensed
  isPrefill
  discardAfterDate
  workflowStatus
  numberOfLabels
  dosesPerDay
  unitsPerDose
  effectiveDate
  verifiedSIGExpanded
  responsePatientPayAmount
}
    `;
export const RxFragmentDoc = gql`
    fragment Rx on Rx {
  customerId
  dateExpires
  dateWritten
  id
  isCancelled
  originalDaysSupply
  originalQuantity
  originalSig
  originalSigExpanded
  prescribedDrugId
  prescriberId
  refillsAuthorized
  refillsRemaining
  rxNumber
}
    `;
export const AddDependentDocument = gql`
    mutation AddDependent($data: CreateCustomerInput!) {
  addDependent(data: $data) {
    ...CustomerWithProfile
  }
}
    ${CustomerWithProfileFragmentDoc}`;
export type AddDependentMutationFn = Apollo.MutationFunction<AddDependentMutation, AddDependentMutationVariables>;

/**
 * __useAddDependentMutation__
 *
 * To run a mutation, you first call `useAddDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDependentMutation, { data, loading, error }] = useAddDependentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddDependentMutation(baseOptions?: Apollo.MutationHookOptions<AddDependentMutation, AddDependentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDependentMutation, AddDependentMutationVariables>(AddDependentDocument, options);
      }
export type AddDependentMutationHookResult = ReturnType<typeof useAddDependentMutation>;
export type AddDependentMutationResult = Apollo.MutationResult<AddDependentMutation>;
export type AddDependentMutationOptions = Apollo.BaseMutationOptions<AddDependentMutation, AddDependentMutationVariables>;
export const AddDependentStep2Document = gql`
    query AddDependentStep2($id: String!) {
  customer(id: $id) {
    ...CustomerWithProfile
    phone {
      ...CustomerPhoneNumber
    }
  }
}
    ${CustomerWithProfileFragmentDoc}
${CustomerPhoneNumberFragmentDoc}`;

/**
 * __useAddDependentStep2Query__
 *
 * To run a query within a React component, call `useAddDependentStep2Query` and pass it any options that fit your needs.
 * When your component renders, `useAddDependentStep2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDependentStep2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddDependentStep2Query(baseOptions: Apollo.QueryHookOptions<AddDependentStep2Query, AddDependentStep2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDependentStep2Query, AddDependentStep2QueryVariables>(AddDependentStep2Document, options);
      }
export function useAddDependentStep2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDependentStep2Query, AddDependentStep2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDependentStep2Query, AddDependentStep2QueryVariables>(AddDependentStep2Document, options);
        }
export type AddDependentStep2QueryHookResult = ReturnType<typeof useAddDependentStep2Query>;
export type AddDependentStep2LazyQueryHookResult = ReturnType<typeof useAddDependentStep2LazyQuery>;
export type AddDependentStep2QueryResult = Apollo.QueryResult<AddDependentStep2Query, AddDependentStep2QueryVariables>;
export const CustomerWithAddressDocument = gql`
    query customerWithAddress($id: String!) {
  customer(id: $id) {
    id
    customerAddresses {
      ...CustomerAddress
    }
  }
}
    ${CustomerAddressFragmentDoc}`;

/**
 * __useCustomerWithAddressQuery__
 *
 * To run a query within a React component, call `useCustomerWithAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerWithAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerWithAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerWithAddressQuery(baseOptions: Apollo.QueryHookOptions<CustomerWithAddressQuery, CustomerWithAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerWithAddressQuery, CustomerWithAddressQueryVariables>(CustomerWithAddressDocument, options);
      }
export function useCustomerWithAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerWithAddressQuery, CustomerWithAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerWithAddressQuery, CustomerWithAddressQueryVariables>(CustomerWithAddressDocument, options);
        }
export type CustomerWithAddressQueryHookResult = ReturnType<typeof useCustomerWithAddressQuery>;
export type CustomerWithAddressLazyQueryHookResult = ReturnType<typeof useCustomerWithAddressLazyQuery>;
export type CustomerWithAddressQueryResult = Apollo.QueryResult<CustomerWithAddressQuery, CustomerWithAddressQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: EmailAddress!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MedicationDocument = gql`
    query Medication($id: ID!) {
  rx(id: $id) {
    ...Rx
    prescribedDrug {
      ...Drug
    }
    prescriberId
    prescriber {
      ...Prescriber
    }
    refills {
      ...Refill
      trackingInfo {
        ...TrackingInfo
      }
      drug {
        ...Drug
      }
    }
  }
}
    ${RxFragmentDoc}
${DrugFragmentDoc}
${PrescriberFragmentDoc}
${RefillFragmentDoc}
${TrackingInfoFragmentDoc}`;

/**
 * __useMedicationQuery__
 *
 * To run a query within a React component, call `useMedicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicationQuery(baseOptions: Apollo.QueryHookOptions<MedicationQuery, MedicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicationQuery, MedicationQueryVariables>(MedicationDocument, options);
      }
export function useMedicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicationQuery, MedicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicationQuery, MedicationQueryVariables>(MedicationDocument, options);
        }
export type MedicationQueryHookResult = ReturnType<typeof useMedicationQuery>;
export type MedicationLazyQueryHookResult = ReturnType<typeof useMedicationLazyQuery>;
export type MedicationQueryResult = Apollo.QueryResult<MedicationQuery, MedicationQueryVariables>;
export const CreateRefillDocument = gql`
    mutation CreateRefill($rxNumber: Int!) {
  createRefill(rxNumber: $rxNumber) {
    id
    rxNumber
    reasons
  }
}
    `;
export type CreateRefillMutationFn = Apollo.MutationFunction<CreateRefillMutation, CreateRefillMutationVariables>;

/**
 * __useCreateRefillMutation__
 *
 * To run a mutation, you first call `useCreateRefillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefillMutation, { data, loading, error }] = useCreateRefillMutation({
 *   variables: {
 *      rxNumber: // value for 'rxNumber'
 *   },
 * });
 */
export function useCreateRefillMutation(baseOptions?: Apollo.MutationHookOptions<CreateRefillMutation, CreateRefillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRefillMutation, CreateRefillMutationVariables>(CreateRefillDocument, options);
      }
export type CreateRefillMutationHookResult = ReturnType<typeof useCreateRefillMutation>;
export type CreateRefillMutationResult = Apollo.MutationResult<CreateRefillMutation>;
export type CreateRefillMutationOptions = Apollo.BaseMutationOptions<CreateRefillMutation, CreateRefillMutationVariables>;
export const MedicationsPageDocument = gql`
    query MedicationsPage {
  me {
    ...User
    customer {
      id
      arAccount {
        ...ARAccount
        customerGroups {
          ...ARCustomerGroup
          customer {
            ...CustomerWithProfile
            rxes {
              ...Rx
              prescribedDrug {
                ...Drug
              }
            }
          }
        }
      }
    }
  }
}
    ${UserFragmentDoc}
${ArAccountFragmentDoc}
${ArCustomerGroupFragmentDoc}
${CustomerWithProfileFragmentDoc}
${RxFragmentDoc}
${DrugFragmentDoc}`;

/**
 * __useMedicationsPageQuery__
 *
 * To run a query within a React component, call `useMedicationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicationsPageQuery(baseOptions?: Apollo.QueryHookOptions<MedicationsPageQuery, MedicationsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicationsPageQuery, MedicationsPageQueryVariables>(MedicationsPageDocument, options);
      }
export function useMedicationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicationsPageQuery, MedicationsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicationsPageQuery, MedicationsPageQueryVariables>(MedicationsPageDocument, options);
        }
export type MedicationsPageQueryHookResult = ReturnType<typeof useMedicationsPageQuery>;
export type MedicationsPageLazyQueryHookResult = ReturnType<typeof useMedicationsPageLazyQuery>;
export type MedicationsPageQueryResult = Apollo.QueryResult<MedicationsPageQuery, MedicationsPageQueryVariables>;
export const CustomerAndDependentsWithAddressesDocument = gql`
    query CustomerAndDependentsWithAddresses($id: String!) {
  customer(id: $id) {
    id
    arAccount {
      id
      customerGroups {
        customer {
          id
          firstName
          lastName
          customerAddresses {
            ...CustomerAddress
          }
        }
      }
    }
  }
}
    ${CustomerAddressFragmentDoc}`;

/**
 * __useCustomerAndDependentsWithAddressesQuery__
 *
 * To run a query within a React component, call `useCustomerAndDependentsWithAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAndDependentsWithAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAndDependentsWithAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerAndDependentsWithAddressesQuery(baseOptions: Apollo.QueryHookOptions<CustomerAndDependentsWithAddressesQuery, CustomerAndDependentsWithAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerAndDependentsWithAddressesQuery, CustomerAndDependentsWithAddressesQueryVariables>(CustomerAndDependentsWithAddressesDocument, options);
      }
export function useCustomerAndDependentsWithAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerAndDependentsWithAddressesQuery, CustomerAndDependentsWithAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerAndDependentsWithAddressesQuery, CustomerAndDependentsWithAddressesQueryVariables>(CustomerAndDependentsWithAddressesDocument, options);
        }
export type CustomerAndDependentsWithAddressesQueryHookResult = ReturnType<typeof useCustomerAndDependentsWithAddressesQuery>;
export type CustomerAndDependentsWithAddressesLazyQueryHookResult = ReturnType<typeof useCustomerAndDependentsWithAddressesLazyQuery>;
export type CustomerAndDependentsWithAddressesQueryResult = Apollo.QueryResult<CustomerAndDependentsWithAddressesQuery, CustomerAndDependentsWithAddressesQueryVariables>;
export const MemberDetailsDocument = gql`
    query MemberDetails($id: String!) {
  customer(id: $id) {
    ...CustomerWithFullInfo
  }
}
    ${CustomerWithFullInfoFragmentDoc}`;

/**
 * __useMemberDetailsQuery__
 *
 * To run a query within a React component, call `useMemberDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberDetailsQuery(baseOptions: Apollo.QueryHookOptions<MemberDetailsQuery, MemberDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(MemberDetailsDocument, options);
      }
export function useMemberDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberDetailsQuery, MemberDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(MemberDetailsDocument, options);
        }
export type MemberDetailsQueryHookResult = ReturnType<typeof useMemberDetailsQuery>;
export type MemberDetailsLazyQueryHookResult = ReturnType<typeof useMemberDetailsLazyQuery>;
export type MemberDetailsQueryResult = Apollo.QueryResult<MemberDetailsQuery, MemberDetailsQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($data: UpdateCustomerInput!) {
  updateCustomer(data: $data) {
    ...CustomerWithFullInfo
  }
}
    ${CustomerWithFullInfoFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($where: ProfileWhereUniqueInput!, $data: UpdateProfileInput!) {
  updateProfile(where: $where, data: $data) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateCustomerAddressDocument = gql`
    mutation UpdateCustomerAddress($data: UpdateCustomerAddressInput!) {
  updateCustomerAddress(data: $data) {
    ...CustomerAddress
  }
}
    ${CustomerAddressFragmentDoc}`;
export type UpdateCustomerAddressMutationFn = Apollo.MutationFunction<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>;

/**
 * __useUpdateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAddressMutation, { data, loading, error }] = useUpdateCustomerAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>(UpdateCustomerAddressDocument, options);
      }
export type UpdateCustomerAddressMutationHookResult = ReturnType<typeof useUpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationResult = Apollo.MutationResult<UpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>;
export const CreateCustomerAddressDocument = gql`
    mutation CreateCustomerAddress($data: CreateCustomerAddressInput!) {
  createCustomerAddress(data: $data) {
    ...CustomerAddress
  }
}
    ${CustomerAddressFragmentDoc}`;
export type CreateCustomerAddressMutationFn = Apollo.MutationFunction<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>;

/**
 * __useCreateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAddressMutation, { data, loading, error }] = useCreateCustomerAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomerAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>(CreateCustomerAddressDocument, options);
      }
export type CreateCustomerAddressMutationHookResult = ReturnType<typeof useCreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationResult = Apollo.MutationResult<CreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>;
export const MemberPageDocument = gql`
    query MemberPage {
  me {
    ...User
    customer {
      ...Customer
      arAccount {
        ...ARAccount
        customerGroups {
          ...ARCustomerGroup
          customer {
            ...CustomerWithProfile
          }
        }
      }
    }
  }
}
    ${UserFragmentDoc}
${CustomerFragmentDoc}
${ArAccountFragmentDoc}
${ArCustomerGroupFragmentDoc}
${CustomerWithProfileFragmentDoc}`;

/**
 * __useMemberPageQuery__
 *
 * To run a query within a React component, call `useMemberPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberPageQuery(baseOptions?: Apollo.QueryHookOptions<MemberPageQuery, MemberPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberPageQuery, MemberPageQueryVariables>(MemberPageDocument, options);
      }
export function useMemberPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberPageQuery, MemberPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberPageQuery, MemberPageQueryVariables>(MemberPageDocument, options);
        }
export type MemberPageQueryHookResult = ReturnType<typeof useMemberPageQuery>;
export type MemberPageLazyQueryHookResult = ReturnType<typeof useMemberPageLazyQuery>;
export type MemberPageQueryResult = Apollo.QueryResult<MemberPageQuery, MemberPageQueryVariables>;
export const OnboardingStep2Document = gql`
    query OnboardingStep2 {
  me {
    id
    customer {
      ...CustomerWithProfile
      phone {
        ...CustomerPhoneNumber
      }
    }
  }
}
    ${CustomerWithProfileFragmentDoc}
${CustomerPhoneNumberFragmentDoc}`;

/**
 * __useOnboardingStep2Query__
 *
 * To run a query within a React component, call `useOnboardingStep2Query` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStep2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStep2Query({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingStep2Query(baseOptions?: Apollo.QueryHookOptions<OnboardingStep2Query, OnboardingStep2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingStep2Query, OnboardingStep2QueryVariables>(OnboardingStep2Document, options);
      }
export function useOnboardingStep2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStep2Query, OnboardingStep2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingStep2Query, OnboardingStep2QueryVariables>(OnboardingStep2Document, options);
        }
export type OnboardingStep2QueryHookResult = ReturnType<typeof useOnboardingStep2Query>;
export type OnboardingStep2LazyQueryHookResult = ReturnType<typeof useOnboardingStep2LazyQuery>;
export type OnboardingStep2QueryResult = Apollo.QueryResult<OnboardingStep2Query, OnboardingStep2QueryVariables>;
export const RegisterDocument = gql`
    mutation Register($email: EmailAddress!, $firstName: String!, $lastName: String!) {
  register(email: $email, firstName: $firstName, lastName: $lastName)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const VerifyRegistrationDocument = gql`
    mutation VerifyRegistration($password: String!, $token: String!, $tokenId: String!) {
  verifyRegistration(password: $password, token: $token, tokenId: $tokenId) {
    user {
      ...User
    }
    token
  }
}
    ${UserFragmentDoc}`;
export type VerifyRegistrationMutationFn = Apollo.MutationFunction<VerifyRegistrationMutation, VerifyRegistrationMutationVariables>;

/**
 * __useVerifyRegistrationMutation__
 *
 * To run a mutation, you first call `useVerifyRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyRegistrationMutation, { data, loading, error }] = useVerifyRegistrationMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useVerifyRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyRegistrationMutation, VerifyRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyRegistrationMutation, VerifyRegistrationMutationVariables>(VerifyRegistrationDocument, options);
      }
export type VerifyRegistrationMutationHookResult = ReturnType<typeof useVerifyRegistrationMutation>;
export type VerifyRegistrationMutationResult = Apollo.MutationResult<VerifyRegistrationMutation>;
export type VerifyRegistrationMutationOptions = Apollo.BaseMutationOptions<VerifyRegistrationMutation, VerifyRegistrationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($tokenId: String!, $resetToken: String!, $password: String!) {
  resetPassword(tokenId: $tokenId, resetToken: $resetToken, password: $password) {
    token
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      resetToken: // value for 'resetToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;